:root {
  --spacing-01: 0.25rem; // 4px
  --spacing-02: 0.5rem; // 8px
  --spacing-03: 0.75rem; // 12px
  --spacing-04: 1rem; // 16px
  --spacing-05: 1.25rem; // 20px
  --spacing-06: 1.5rem; // 24px
  --spacing-07: 1.75rem; // 28px
  --spacing-08: 2rem; // 32px
  --spacing-10: 2.5rem; // 40px
  --spacing-12: 3rem; // 48px
  --spacing-14: 3.5rem; // 56px
  --spacing-16: 4rem; // 64px
  --spacing-20: 5rem; // 80px
  --spacing-24: 6rem; // 96px
  --primary: #fa002e;
  --blue: #0036f2;
  --blue-light: #00acf2;
  --yellow: #f9be03;
  --black: #020203;
  --black-text: #212427;
  --black-rgb: 2 2 3;
  --black-light: rgb(21 21 21 / 45%);
  --white: #fff;
  --gray-bright: #f1f2f4;
  --gray-light: #d8dadd;
  --gray-medium: #c0c2c6;
  --gray-dusky: #a2a2a2;
  --gray-dark: #74767c;
  --gray-extra-dark: #55565b;
  --gray-ad: #f5f5f5;
  --gray-2: #65676d;
  --transition-default: 250ms ease-in-out;
  /* stylelint-disable */
  // gallery override
  --yarl__color_button_disabled: rgb(247 248 249 / 40%);
  --yarl__navigation_button_padding: 0;
  /* stylelint-enable */
}
