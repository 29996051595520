@font-face {
font-family: 'fontRtlUnited';
src: url(/_next/static/media/e96cda5e69a70db3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'fontRtlUnited';
src: url(/_next/static/media/a839df46a297a209-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'fontRtlUnited Fallback';src: local("Arial");ascent-override: 98.65%;descent-override: 24.61%;line-gap-override: 0.00%;size-adjust: 99.95%
}.__className_0c91b3 {font-family: 'fontRtlUnited', 'fontRtlUnited Fallback'
}

:root{--spacing-01: 0.25rem;--spacing-02: 0.5rem;--spacing-03: 0.75rem;--spacing-04: 1rem;--spacing-05: 1.25rem;--spacing-06: 1.5rem;--spacing-07: 1.75rem;--spacing-08: 2rem;--spacing-10: 2.5rem;--spacing-12: 3rem;--spacing-14: 3.5rem;--spacing-16: 4rem;--spacing-20: 5rem;--spacing-24: 6rem;--primary: #fa002e;--blue: #0036f2;--blue-light: #00acf2;--yellow: #f9be03;--black: #020203;--black-text: #212427;--black-rgb: 2 2 3;--black-light: rgb(21 21 21 / 45%);--white: #fff;--gray-bright: #f1f2f4;--gray-light: #d8dadd;--gray-medium: #c0c2c6;--gray-dusky: #a2a2a2;--gray-dark: #74767c;--gray-extra-dark: #55565b;--gray-ad: #f5f5f5;--gray-2: #65676d;--transition-default: 250ms ease-in-out;--yarl__color_button_disabled: rgb(247 248 249 / 40%);--yarl__navigation_button_padding: 0}*{box-sizing:border-box}html,body{height:100%;margin:0;-webkit-font-smoothing:antialiased}:focus-visible{outline:1px dotted var(--gray-medium)}a{color:inherit;text-decoration:none}p{margin:0 0 var(--spacing-04)}@media only screen and (min-width: 1236px){p{margin-bottom:var(--spacing-06)}}h1,h2,h3,h4,h5,h6{margin:0;font-weight:normal}section{scroll-margin-top:160px}button{margin:0;padding:0;font-family:inherit}
