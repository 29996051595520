@use 'base/variables';
@use 'base/mixins';

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

:focus-visible {
  outline: 1px dotted var(--gray-medium);
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0 0 var(--spacing-04);

  @include mixins.breakpoint(l) {
    margin-bottom: var(--spacing-06);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

section {
  scroll-margin-top: 160px;
}

button {
  margin: 0;
  padding: 0;
  font-family: inherit;
}
